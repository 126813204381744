<template>
  <Parallax />
  <div class="pageDX mb-16">
    <div class="d-flex justify-start text-h2">Vista DX</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex justify-start font-weight-light text-h4 mt-10">
      Üstün konuşma anlayışı
    </div>
    <div class="d-flex flex-wrap justify-space-between my-4">
      <div class="secondWidth">
        <div class="d-flex text-left font-weight-regular mt-4">
          Vista DX teknolojisi, müşterilerin her zamankinden daha fazla
          konuşmayı anlamalarına olanak tanır. Yeni ses özelliklerimiz,
          müşterinin konuşmanın nereden geldiğini ve kelimelerin arkasındaki
          anlamı algılamasına yardımcı olur*. Ve şimdi Vista DX platformu, üç
          yeni ITE stilini ve yeni Vista DX M 312 BTE'yi içeren genişletilmiş
          bir ürün serisine sahip.
        </div>
      </div>
      <div class="secondWidth">
        <div class="d-flex text-left font-weight-regular mt-4">
          İnce ayarları uzaktan gerçekleştirmenize yardımcı olan -yeni bir
          seçenek olan- uzaktan ayarlama ile müşterilere hizmet verme şeklinizde
          artırılmış esnekliğin keyfini çıkarın.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          Vista DX'in akıllı ses işleme özelliği, daha iyi anlama için
          konuşmadaki ince nüansları geliştirmeye yardımcı olur
        </div>
      </div>
    </div>
    <div class="d-flex justify-center font-weight-light text-h4 mt-10">
      Vista DX Modelleri
    </div>
    <div class="pa-8 responsive">
      <v-img class="" :src="require('@/assets/vistaDXModels.png')"></v-img>
    </div>
  </div>
  <div style="background: #3fa399">
    <div class="pageDX">
      <div class="d-flex flex-wrap justify-space-between">
        <div class="thirdWidth my-8">
          <div class="text-left font-weight-black">
            Ortamları 20 yaşındaki bir çocuk kadar doğru bir şekilde tanımlar
          </div>
          <div class="text-left font-weight-light mt-4">
            Müşteriler, Auto Sound Control 3.0 ile tüm dinleme koşullarında
            ellerinden gelenin en iyisini duyabilir ve Media Control'ün
            yardımıyla hayatı zenginleştiren medyanın keyfini çıkarabilir.
            Algoritmalarımızı makine öğrenimi ile yıllarca eğitmek, bu özelliğin
            hayatın sürekli değişen ses manzaralarını tıpkı genç, normal işiten
            bir dinleyicinin yapacağı gibi tanımlayabileceği anlamına gelir.*
          </div>
        </div>
        <div class="thirdWidth my-8">
          <div class="text-left font-weight-black">Dengeli bir sistem</div>
          <div class="text-left font-weight-regular mt-4">
            Sound Director ile her ortamda rahat bir şekilde dengelenmiş net
            konuşma, azaltılmış gürültü ve genel ses kalitesi sağlayın. Ve yeni
            yumuşak konuşma yoğunlaştırıcı özelliğimiz, müşterilerin zorlu
            ortamlarda yumuşak konuşmayı anlamasını kolaylaştırmak için
            konuşmanın ayrıntılarını vurgular.
          </div>
        </div>
        <div class="thirdWidth my-8">
          <div class="text-left font-weight-black">
            Gürültüde bile konuşmaları inanılmaz netlikte duyun
          </div>
          <div class="text-left font-weight-regular mt-4">
            Speech Target Pro ile müşterilerinizin bir numaralı gürültülü
            ortamlarda daha iyi duyma ihtiyacını karşılayın.** Konuşmanın
            nereden geldiğini bilmek için binaural ipuçlarını kullanır ve onu
            tipik bir işitme cihazının yapabileceğinin ötesinde geliştirir.
            Speech Target Pro'nun yeni bir dördüncü boyutu olan Speech
            Separator, müşterilerin kelimeleri ve bunların arkasındaki gerçek
            anlamı daha iyi anlamalarına yardımcı olmak için tasarlandı.
          </div>
        </div>
        <div class="thirdWidth my-8">
          <div class="text-left font-weight-black">Doğal yerelleştirme</div>
          <div class="text-left font-weight-regular mt-4">
            Müşteriler gerçekçi bir ses deneyiminin keyfini çıkarır çünkü sesler
            akustik olarak ayrılabilir ve doğru konumlarında algılanabilir. Bu,
            yenilikçi 4 mikrofon stratejisiyle doğal yerelleştirme ipuçlarını
            geri getiren Sound Mapping sayesindedir.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pageDX mb-16">
    <div class="d-flex justify-center text-h2">Vista DX</div>
    <div class="d-flex flex-wrap justify-space-between mt-8">
      <div class="narrowWidth pa-8">
        <v-img class="" :src="require('@/assets/vistaDXSet.png')"></v-img>
      </div>
      <div class="d-flex wideWidth pa-16">
        <div class="">
          <div class="d-flex text-start font-weight-light mt-5">
            Konuşmalarda anlamın anlaşılmasını geliştirmek için inanılmaz ses
            performansı
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Tüm gün dayanan kanıtlanmış lityum-iyon şarj edilebilir teknoloji
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Hızlı, kolay ve doğru ilk uyum, onları kolaylaştıran ve daha da
            iyiye giden sesle
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Uzaktan ince ayar ayarlarıyla gelişmiş esneklik
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Herkes için yapılmış (MFA) bağlantı özelliğine sahip bir ITE de
            dahil olmak üzere şık ve konforlu eksiksiz bir ürün portföyü
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Tüm telefonlarda eller serbest telefon görüşmeleri, görüntülü
            aramalar ve her iki kulağa stereo medya akışı
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Vista:trial ile günlük yaşamlarında risksiz değerlendirme
          </div>
          <v-divider></v-divider>
          <div class="d-flex text-start font-weight-light mt-5">
            Vista:upgrade ile geleceğe hazır işitme cihazları
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VistaDX",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageDX {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 48%;
}

.thirdWidth {
  width: 24%;
  margin: 0px 4px;
}

@media only screen and (max-width: 1440px) {
  .pageDX {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageDX {
    margin: 2rem 2rem 0rem 2rem;
  }

  .thirdWidth {
    width: 100%;
  }

  .responsive {
    padding: 20px 0px !important;
  }

  .widthB {
    width: 100%;
  }
}
</style>