<template>
    <Parallax/>
</template>

<script>
import Parallax from '../components/Parallax.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'NotFound',
    components: {
        Parallax
    }
}
</script>

<style>

</style>