<template>
  <Parallax />
  <div class="pageHH">
    <div class="d-flex justify-start text-h4 text-sm-h2 mt-10">Bize Ulaşın</div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="d-flex text-left font-weight-light text-h5 text-decoration-underline mt-8 mt-sm-16"
      style="color: #469597"
    >
      88 Grup İşitme Cihazları Merkezi
    </div>
    <div class="d-flex text-left font-weight-regular mt-1">
      Uzman odyolog ve odyometristlerimiz ile pazar günleri hariç haftanın her
      günü 09:00 ile 18:00 saatleri arasında ofislerimizde görüşebilirsiniz.
    </div>
    <div
      class="d-flex justify-start font-weight-light text-h5 text-decoration-underline mt-8"
      style="color: #469597"
    >
      Telefon
    </div>
    <div class="d-flex text-left font-weight-regular mt-1">
      0332 236 06 59 / +90 (545) 919 44 37
    </div>
    <div
      class="d-flex justify-start font-weight-light text-h5 text-decoration-underline mt-8"
      style="color: #469597"
    >
      Whatsapp
    </div>
    <div class="d-flex text-left font-weight-regular mt-1">
      <v-btn
        variant="plain"
        href="https://wa.me/+905459194437?text=Merhaba! Sizden bir konu hakkında bilgi almak istiyorum."
        target="_blank"
        color="#DDBEAA"
        >+90 (545) 919 44 37</v-btn
      >
    </div>
    <div
      class="d-flex justify-start font-weight-light text-h5 text-decoration-underline mt-8"
      style="color: #469597"
    >
      E-posta
    </div>
    <div class="d-flex text-left font-weight-regular mt-1">
      bilgi@88saglik.com
    </div>
    <div
      class="d-flex justify-start font-weight-light text-h5 text-decoration-underline mt-8"
      style="color: #469597"
    >
      Adres
    </div>
    <div class="d-flex text-left font-weight-regular mt-1 mb-8">
      Ferhuniye Mah. Nalçacı Cad. 18/A (İller Bankası Yanı) Selçuklu/Konya
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Contact",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageHH {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 48%;
}

@media only screen and (max-width: 1440px) {
  .pageHH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageHH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthB {
    width: 100%;
  }
}
</style>