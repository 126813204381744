<template>
  <Parallax />
  <div class="pageHH mb-8">
    <div class="d-flex justify-start text-h4 text-sm-h2 mt-10">Misyonumuz</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex text-left font-weight-regular mt-8">
      Milyonlarca insan, sevdiklerinden ve tutkulu oldukları şeylerden uzakta
      bir hayat yaşıyor. Bunun sebebi ise iyi işitememeleri. Ancak biz, böyle
      olmak zorunda olmadığını biliyoruz ve kabul etmiyoruz. İşitme kaybının
      hayatı nasıl etkilediğinin farkındayız ve bu durumu değiştirebileceğimize
      inanıyoruz.
    </div>
    <div class="d-flex text-left ont-weight-regular mt-4">
      Ancak doğal olarak, bu durumdan huzursuz ve memnuniyetsiziz. Çünkü işitme
      kaybı olan herkes için daha fazlasını ve daha iyisini yapabileceğimize
      inanıyoruz. İşitme uzmanlarıyla dünya çapında işbirliği yaparak, işitme
      engellilerin karşılaştığı zorlukları aşmak ve herkesin tekrar sesin
      güzelliğini deneyimlemesine yardımcı olmak için yeni ve yenilikçi
      yöntemler bulma konusunda tamamen taahhüt veriyoruz.
    </div>
    <div class="d-flex text-left ont-weight-regular mt-4">
      Bu lekeyle mücadele etmek için, işitme kaybıyla yaşayan bireylerin yaşam
      kalitesini artırmayı ve onların hayatlarına olumlu bir etki sağlamayı
      amaçlıyoruz. İşitme cihazları ve diğer ileri teknolojilerle, insanların
      işitme yetilerini eski haline getirmenin ne kadar önemli olduğunu
      biliyoruz. Her bireyin sesin gücünü ve güzelliğini yeniden keşfedebileceği
      bir dünya yaratmak için çalışıyoruz.
    </div>
    <div class="d-flex justify-start text-h4 text-sm-h2 mt-14">Tarihçemiz</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex text-left ont-weight-regular mt-8">
      İşitme çözümleri alanında lider bir taahhüdümüz var.
      Teknolojideki ilerlemeleri takip ederek, müşteri odaklı yaklaşımımızla en
      modern işitme çözümlerini geliştiriyoruz. Müşterilerimizin yaşam
      kalitesini artırmak ve daha iyi bir işitme deneyimi sunmak için sürekli
      çalışıyoruz. Geleceğe yönelik yatırımlarımızla işitme kaybı olan herkes
      için daha fazlasını ve daha iyisini sunmayı hedefliyoruz.
    </div>
    <div class="d-flex justify-start text-h4 text-sm-h2 mt-14">Teknolojilerimiz</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex text-left ont-weight-regular my-8">
      En zorlu işitme durumlarıyla başa çıkma konusundaki taahhüdümüzle,
      teknolojinin sınırlarını aşmayı hedefliyoruz. Sürekli olarak en yeni ana
      teknolojileri takip ediyor ve geliştiriyoruz. Ürünlerimizi gerçekten
      benzersiz kılan özelliklerle, müşterilerimize en iyi işitme deneyimini
      sunmayı amaçlıyoruz. İşitme çözümlerindeki liderliğimizle, herkesin sesin
      güzelliğini yeniden keşfetmesine yardımcı olmak için özveriyle
      çalışıyoruz. Teknoloji ve yeniliklerle işitme engellerini aşmaya
      kararlıyız, çünkü bizim için en değerli olan sizsiniz.
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageHH {
  margin: 0rem 20rem 0rem 20rem;
}

@media only screen and (max-width: 1440px) {
  .pageHH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageHH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .mobile {
    display: none;
  }
}
</style>