// Composables
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "../views/Home.vue";
import Products from "../views/Products.vue";
import Contact from "../views/Contact.vue";
import Hearing from "../views/Hearing.vue";
import HearingTest from "../views/HearingTest.vue";
import SGKShare from "../views/SGKShare.vue";
import NotFound from "../views/NotFound.vue";
import KocklearImplant from "../views/KocklearImplant.vue";
import About from "../views/About.vue";
import PriceList from "../views/PriceList.vue";
import ResoundOne from "../views/pages/ResoundOne.vue";
import ResoundQuattro from "../views/pages/ResoundQuattro.vue";
import ResoundKey from "../views/pages/ResoundKey.vue";
import ResoundEnzo from "../views/pages/ResoundEnzo.vue";
import VistaB from "../views/pages/VistaB.vue";
import VistaBPlug from "../views/pages/VistaBPlug.vue";
import VistaDX from "../views/pages/VistaDX.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
  },
  {
    path: "/products",
    name: "Products",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Products,
  },
  {
    path: "/resoundOne",
    name: "ResoundOne",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResoundOne,
  },
  {
    path: "/resoundQuattro",
    name: "ResoundQuattro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResoundQuattro,
  },
  {
    path: "/resoundKey",
    name: "ResoundKey",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResoundKey,
  },
  {
    path: "/resoundEnzo",
    name: "ResoundEnzo",
    // route level codting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ResoundEnzo,
  },
  {
    path: "/vistaB",
    name: "VistaB",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VistaB,
  },
  {
    path: "/vistaBPlug",
    name: "VistaBPlug",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VistaBPlug,
  },
  {
    path: "/vistaDX",
    name: "VistaDX",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VistaDX,
  },
  {
    path: "/priceList",
    name: "PriceList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PriceList,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Contact,
  },
  {
    path: "/hearing",
    name: "Hearing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Hearing,
  },
  {
    path: "/hearingTest",
    name: "HearingTest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HearingTest,
  },
  {
    path: "/sgkShare",
    name: "SGKShare",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SGKShare,
  },
  {
    path: "/kocklearImplant",
    name: "KocklearImplant",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: KocklearImplant,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Sayfa geçişleri arasında kaydedilen pozisyonu kullan
    } else {
      return { top: 0 }; // Sayfanın en üstüne kaydır
    }
  },
});

export default router;
