<template>
  <Parallax />
  <div class="page mb-16">
    <div class="d-flex justify-start text-h2">Vista B (Kulak İçi)</div>
    <v-divider class="mt-2"></v-divider>
    <div class="text-left font-weight-light text-h6 mt-10">
      Vista B işitme cihazları konforlu ve kolay kullanım için tasarlandı
    </div>
    <div class="d-flex">
      <div class="widthB align-self-center">
        <div class="text-left font-weight-light text-h3 mt-10">
          3 Adımda mükemmel işitme çözümünüzü keşfedin
        </div>
      </div>
      <div class="widthB">
        <v-img :src="require('@/assets/vistaBPlug3.png')"></v-img>
      </div>
    </div>
    <div>
      <div class="d-flex text-left font-weight-light text-h6 mt-16">
        Adım 1:
      </div>
      <div class="text-left font-weight-regular text-h3 mt-4 mobileSize">
        İşitme zorluklarını nerelerde yaşıyorsunuz?
      </div>
    </div>
    <div class="my-10">
      <v-img :src="require(`@/assets/hearingChart.png`)"></v-img>
    </div>
    <div class="pb-16">
      <div class="d-flex text-left font-weight-light text-h6 mt-14">
        Adım 2:
      </div>
      <div class="text-left font-weight-regular text-h3 mt-4 mobileSize">
        Hangi model sizin için en uygun?
      </div>
      <div class="text-left font-weight-light text-h6 mt-8">
        Vista B işitme cihazlarımız, kullanım rahatlığı ve kullanım kolaylığı
        için tasarlanmıştır.
      </div>
      <div class="text-left font-weight-light text-h6 mt-2">
        Bluetooth uyumludurlar, böylece müzik, podcast, çağrı veya dijital
        asistanları doğrudan işitme cihazlarınıza aktarabilir, medyanızı* tek
        bir dokunuşla kontrol edebilirsiniz.
      </div>
      <div class="text-left font-weight-light text-h6 mt-2">
        Şarj edilebilir seçeneklerin şarj edilmesi hızlıdır ve tüm gün dayandığı
        kanıtlanmıştır.
      </div>
      <div
        class="text-left font-weight-regular text-h6 mt-2"
        style="color: teal"
      >
        İşitme cihazı merkezimizdeki uzman odyologlar sayesinde sizin için en
        uygun işitme cihazını bulalım, hayatınıza ses katalım.
      </div>
    </div>
  </div>
  <div class="py-16 mb-16" style="background: #3fa399">
    <div class="d-flex flex-wrap mt-14 page">
      <div class="align-self-center widthB pa-8">
        <div class="d-flex text-left font-weight-light text-h6">Adım 3:</div>
        <div class="text-left font-weight-regular text-h3 mt-4 mobileSize">
          Hangi teknoloji seviyesi senin için en iyisi?
        </div>
        <div class="text-left font-weight-regular text-h6 mt-12">
          Bu tabloları inceleyerek doğru karara varabilirsin.
        </div>
      </div>
      <div class="widthB">
        <v-img :src="require('@/assets/levelChart.png')"></v-img>
      </div>
    </div>
    <div class="page">
      <div class="mt-16">
        <div class="text-h4 pt-8 pb-2">Detaylı Seviye Tablosu</div>
        <v-divider></v-divider>
        <v-img
          class="mt-8"
          :src="require('@/assets/levelDetailChart.png')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VistaB",
  components: {
    Parallax,
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.page {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 45% !important;
}

.thirdWidth {
  width: 24%;
  margin: 0px 4px;
}

@media only screen and (max-width: 1440px) {
  .page {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .page {
    margin: 2rem 2rem 0rem 2rem;
  }

  .mobileSize {
    font-size: 30px !important;
    font-weight: 300 !important;
  }

  .thirdWidth {
    width: 100%;
  }

  .responsive {
    padding: 20px 0px !important;
  }

  .widthB {
    width: 100% !important;
  }
}
</style>