<template>
  <v-parallax height="300" :src="require('@/assets/home.jpg')"></v-parallax>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Parallax'
}
</script>

<style>

</style>