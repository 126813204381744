<template>
  <Parallax />
  <div class="pagePP mb-16">
    <div class="d-flex justify-start text-h4 text-sm-h2">Ürünlerimiz</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex text-left font-weight-regular mt-8">
      İşitme cihazları, son yıllarda önemli bir evrim geçirdi. Hafiften yoğun
      düzeye kadar farklı işitme kaybı seviyelerine yönelik, 88 Grup İşitme
      olarak hayatınızı kesintisiz bir şekilde sürdürebilmeniz için en uygun
      çözümleri sunuyoruz. İşitme cihazlarının boyutları ve özellikleri geniş
      bir yelpazede değişir ve doğru seçimi yapmanız için işitme uzmanının
      rehberliği hayati öneme sahiptir. Hangi işitme kaybı seviyesinde olursanız
      olun veya hangi ihtiyacınızı taşırsanız taşıyın, yukarıdaki sekme
      seçeneklerinden birinde size yardımcı olacak bilgileri bulabilirsiniz. Siz
      de hayatınıza işitme sağlığınızı en iyi şekilde destekleyen bir adım atın.
    </div>
    <ProductSlide />
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";
import ProductSlide from "../components/ProductSlide.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Products",
  components: {
    Parallax,
    ProductSlide
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.pagePP {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 48%;
}

@media only screen and (max-width: 1440px) {
  .pageHH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageHH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthB {
    width: 100%;
  }
}
</style>