<template>
  <Parallax />
  <div class="pageEEE mb-16">
    <div class="d-flex justify-start text-h2">ReSound Enzo</div>
    <v-divider class="mt-2"></v-divider>
    <v-img class="mt-8" :src="require('@/assets/kidWithMother.jpg')">
      <div class="d-flex justify-end">
        <div class="widhtEE">
          <div class="d-flex text-start font-weight-light mt-12 ml-12 mobile">
            ReSound ENZO Q™
          </div>
          <div class="d-flex text-start font-weight-light text-h3 mt-6 ml-12 mobile">
            Güçlü olmanın çok daha ötesinde
          </div>
        </div>
      </div>
    </v-img>
    <div class="d-flex justify-center font-weight-light text-h4 mt-10">
      Olağanüstü ses kalitesi
    </div>
    <div class="d-flex text-center font-weight-light text-h6 mt-4">
      Özgüven,çevrenizdeki tüm seslerin net ve konforlu olduğu, güvenilir ve
      zahmetsiz bir işitme deneyimiyle başlar. Potansiyelinizi tümüyle açığa
      çıkarın.
    </div>
    <div class="justify-space-between my-12">
      <div class="">
        <div class="text-left font-weight-regular mt-4">
          Güçlü olmanın ötesine geçen, eksiksiz bir işitme çözümü İleri ve çok
          ileri derecede işitme kaybına yönelik en yeni işitme cihazımız ReSound
          ENZO Q, net ve konforlu seslerden ve geniş kapsamlı bağlantı
          seçenekleri ve destek hizmetlerinden yararlanabilmeniz için en
          gelişmiş teknolojiyle tasarlandı.
        </div>
        <div class="text-left font-weight-regular mt-4">
          Seslerin netliği son derece önemli olduğundan, işitme deneyimini kendi
          tercihlerinize göre kişiselleştirebilirsiniz. İşitme cihazının sesini
          sonuna kadar açsanız bile, hiçbir ıslık veya ötme sesi olmayacağını
          bilmenin rahatlığını yaşayın.
        </div>
      </div>
      <div class="">
        <div class="text-left font-weight-regular mt-4">
          Gürültülü ortamlarda ön cepheden gelen konuşmaları tanımada %60
          iyileşme* Gürültülü yerlerde duyabilmek için çaba ve enerji harcamanız
          gerekir. Dinleme tercihlerinizi akıllıca destekleyen, ama bunu
          yaparken çevreyle bağınızı da kopartmayan ReSound ENZO Q ile gürültülü
          ortamlarda daha iyi işitirsiniz. Grup sohbetlerini takip eder ve
          çevrenizdeki tüm seslerin nereden geldiğini işitirken, bir yandan da
          konuşmaya ve diğer ses ayrıntılarına odaklanabilirsiniz.
        </div>
        <div class="text-left font-weight-regular mt-4">
          Araştırmalara göre, teknolojimizi kullanan ileri ve çok ileri derecede
          işitme kaybı olan kişiler, gürültülü ortamlarda ön cepheden gelen
          konuşmaları ortalama %60 oranında daha iyi tanıyabilmektedir.
        </div>
      </div>
    </div>
    <div class="d-flex justify-center font-weight-light text-h3 mt-16">
      Şık Güvenilir Sağlam
    </div>
    <div class="text-center font-weight-light mt-8">
      ReSound ENZO Q aşağıdaki stillerde sunulmaktadır:
    </div>

    <div class="justify-space-between mt-16 d-flex flex-wrap">
      <div class="widthEE">
        <v-img
          class=""
          :width="576"
          height="380"
          :src="require('@/assets/resoundEnzo.png')"
        ></v-img>
        <div class="text-center font-weight-light text-h5 mt-8">
          Süper Güç SP BTE 98
        </div>
      </div>
      <div class="widthEE">
        <v-img
          class=""
          :width="576"
          height="380"
          :src="require('@/assets/resoundEnzo2.png')"
        ></v-img>
        <div class="text-center font-weight-light text-h5 mt-8">
          Yüksek Güç HP BTE 88
        </div>
      </div>
    </div>
    <div class="mt-16">
      <v-img :src="require('@/assets/resoundEnzoSet.png')"> </v-img>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ResoundEnzo",
  components: {
    Parallax,
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.pageEEE {
  margin: 2rem 20rem 0 20rem;
}

.widthEE {
  width: 45%;
}

@media only screen and (max-width: 1440px) {
  .pageEEE {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageEEE {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthEE {
    width: 100%;
  }

  .mobile {
    display: none !important;
  }
}
</style>