<template>
  <Parallax />
  <div class="pageKK mb-16">
    <div class="d-flex justify-start text-h2">ReSound Key</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="d-flex narrowWidth">
        <div class="align-self-center pa-2">
          <div class="d-flex justify-start font-weight-light text-h5">
            ReSound Key
          </div>
          <div class="d-flex text-left font-weight-light text-h4 mt-4">
            Mükemmel işitmeye hoş geldiniz
          </div>
          <div class="d-flex text-left font-weight-light mt-4">
            İşitme konusundaki yeni vazgeçilmezlerinizle tanışın.
          </div>
        </div>
      </div>
      <div class="wideWidth pa-8">
        <v-img class="" :src="require('@/assets/resoundKeyPeople.png')"></v-img>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="narrowWidth pa-8">
        <v-img class="" :src="require('@/assets/resoundKeys.png')"></v-img>
      </div>
      <div class="d-flex flex-wrap wideWidth pa-8">
        <div class="align-self-center">
          <div class="d-flex text-start font-weight-light text-h4">
            ReSound Key'e Hoş Geldiniz
          </div>
          <div class="d-flex text-left font-weight-light text-h3 mt-4">
            Güvenle dinleyin
          </div>
          <div class="d-flex text-left font-weight-light mt-4">
            İşitmemiz, bizi dünyaya ve birbirimize bağlamak açısından hayati
            önem taşır. Duygularınızı hayatın sesleriyle yeniden zenginleştirin
            – daha iyi işitmeye giden yolunuzu mümkün olduğunca kolaylaştırdık.
          </div>
          <div class="d-flex text-left font-weight-light mt-4">
            Mükemmel bir işitme için kendi kulaklarınızla rahat ve güvenilir
            şekilde çalışmak üzere tasarlanmış ReSound Key™ işitme cihazını
            sunuyoruz. Deneyimlerinizle büyümeniz ve her gün en iyisini duymanız
            için size güven ve rehberlik sağlar.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="my-16 bg-brown-lighten-2 py-4 px-16">
    <div class="text-h5 font-weight-light">Avantajlarınız:</div>
    <div class="d-flex flex-wrap mt-8 mb-6">
      <div class="px-5 advantage">
        <div><v-icon icon="mdi-bird" size="x-large"></v-icon></div>
        <div>Sesi daha az çabayla net olarak duyun</div>
      </div>
      <div class="px-5 advantage">
        <div><v-icon icon="mdi-pencil" size="x-large"></v-icon></div>
        <div>Sizin için mükemmel bir işitme cihazı tasarımı</div>
      </div>
      <div class="px-5 advantage">
        <div><v-icon icon="mdi-battery-high" size="x-large"></v-icon></div>
        <div>Tek şarjda 30 saate kadar kolay şarj edilebilirlik</div>
      </div>
      <div class="px-5 advantage">
        <div><v-icon icon="mdi-cellphone-sound" size="x-large"></v-icon></div>
        <div>Uyumlu cihazlardan doğrudan ses aktarımı</div>
      </div>
      <div class="px-5 advantage">
        <div><v-icon icon="mdi-road" size="x-large"></v-icon></div>
        <div>Deneyimlerinizi kişiselleştirmek için tek bir kolay uygulama</div>
      </div>
    </div>
  </div>
  <div class="pageKK mb-16">
    <div class="d-flex flex-wrap justify-space-between my-16">
      <div class="d-flex wideWidth">
        <div class="d-flex flex-wrap align-self-center pa-12 mobilePadding">
          <div class="widthK d-flex justify-start font-weight-light text-h4">
            Güvenle iletişim kurun
          </div>
          <div class="widthK d-flex text-left font-weight-light text-h3 mt-4 mobileText">
            Seslere doğal yaklaşımımız
          </div>
          <div class="widthK d-flex text-left font-weight-light mt-8">
            Tüm işitme çözümlerimiz, çevremizdeki dünyayı nasıl doğal olarak
            duyduğumuzdan ve onlarla nasıl etkileşime girdiğimizden esinlenir.
            Bu nedenle tüm ReSound işitme cihazları, doğal işitme deneyimini
            olabildiğince yakın simüle eden benzersiz Organik İşitme felsefemiz
            üzerine kurulmuştur.
          </div>
          <div class="widthK d-flex text-left font-weight-light text-h3 mt-4 mobileText">
            Gerçek anlamda netlik
          </div>
          <div class="widthK d-flex text-left font-weight-light mt-8">
            ReSound Key'in berrak ses kalitesi ile sohbetlere tam olarak
            katılın. Kolayca konuşabileceğinizi ve duyabileceğinizi bilmek
            özgüveninizi artırır.
          </div>
        </div>
      </div>
      <div class="narrowWidth pa-8">
        <v-img
          class=""
          :width="576"
          :src="require('@/assets/littleKid.jpg')"
        ></v-img>
      </div>
    </div>
    <div class="mt-16">
      <div class="font-weight-black">İŞİTME VAZGEÇİLMEZLERİNİZİ KEŞFEDİN</div>
      <div class="text-h3 font-weight-light mt-6 px-16 mobilePadding">
        Sizin için en uygun işitme cihazı hangisi?
      </div>
      <div class="font-weight-regular text-subtitle-1 mt-6 px-16 mx-16 mobilePadding">
        İşitme, yaşam tarzı, istekler ve ihtiyaçlarınıza uygun mükemmel ReSound
        Key'inizi güvenle bulun. Her tür işitme kaybı için.
      </div>
    </div>
  </div>
  <v-img :src="require('@/assets/resoundKeyProducts.png')"> </v-img>
  <div class="mb-16" style="background: #3fa399">
    <div class="pageKK">
      <div class="mt-16 pt-16">
        <div class="font-weight-black">ŞARJ EDİLEBİLİR İŞİTME CİHAZI</div>
        <div class="text-h3 font-weight-light mt-6 px-16 mobilePadding mobileText">
          Şarj edilebilirlik günümüzde vazgeçilmezdir
        </div>
        <div class="font-weight-regular text-subtitle-1 mt-6 px-16 mx-16 mobilePadding">
          En yeni cihazları seviyor veya pilleri değiştirirken zorlanıyorsanız,
          şarj edilebilirlik modern bir gerekliliktir. Şarj edilebilir ReSound
          Key'iniz kolay kullanımlı bir Masaüstü şarj cihazıyla birlikte gelir.
          Seyahat için ideal Premium taşınabilir şarj aletine yükseltmeyi de
          seçebilirsiniz.
        </div>
      </div>
      <div class="d-flex flex-wrap justify-space-between my-16 pb-16">
        <div class="d-flex wideWidth">
          <div class="d-flex align-self-center pa-12">
            <div>
              <div class="d-flex justify-start font-weight-light">
                Tek şarj ile
              </div>
              <div class="d-flex text-left font-weight-regular text-h3">
                30 Saat
              </div>
              <div class="d-flex text-left font-weight-light">güç</div>
              <div class="d-flex text-left font-weight-regular text-h3 mt-4">
                3x şarj
              </div>
              <div class="d-flex text-left font-weight-light">
                Premium şarj aletinde
              </div>
            </div>
          </div>
        </div>
        <div class="narrowWidth">
          <v-img
            :width="576"
            :src="require('@/assets/chargerImage.png')"
          ></v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ResoundKey",
  components: {
    Parallax,
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.pageKK {
  margin: 2rem 20rem 0 20rem;
}

.thirdWidth {
  width: 46%;
  height: 400px;
}

.advantage {
  width: 20% !important;
}

.widthK {
    width: 100% !important;
  }

.wideWidth {
  width: 58%;
}

.narrowWidth {
  width: 38%;
}

@media only screen and (max-width: 1440px) {
  .pageKK {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageKK {
    margin: 2rem 2rem 0rem 2rem;
  }

  .mobileText {
    font-size: 40px !important;
    font-weight: 300 !important;
  }

  .mobilePadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  .widthK {
    width: 100% !important;
  }

  .advantage {
    width: 100% !important;
    padding-bottom: 32px;
  }

  .thirdWidth {
    padding: 8px !important;
    width: 100%;
    height: 400px;
  }

  .wideWidth {
    width: 100%;
    padding: 8px !important;
  }

  .narrowWidth {
    padding: 8px !important;
    width: 100%;
  }
}
</style>