<template>
  <v-card flat rounded="0">
    <div
      class="d-flex flex-column flex-lg-row align-center justify-sm-end"
      style="background-color: #5ba199"
    >
      <div
        class="d-flex justify-center w-auto my-2 text-subtitle-2 font-weight-light text-white"
      >
        bilgi@88saglik.com / 0332 236 06 59 / +90 (545) 919 44 37
      </div>
      <div
        class="d-flex flex-wrap align-center justify-sm-center responsiveHeader"
      >
        <v-btn variant="text" class="text-subtitle" to="/about" color="#E5E3E4">
          88 GRUP İŞİTME HAKKINDA
        </v-btn>

        <v-divider inset vertical></v-divider>

        <v-btn
          variant="text"
          class="text-subtitle"
          to="/contact"
          color="#E5E3E4"
        >
          İLETİŞİM
        </v-btn>

        <v-divider inset vertical></v-divider>
      </div>
      <div class="responsiveHeader">
        <v-btn
          color="#DDBEAA"
          v-for="obj in icons"
          :href="obj.src"
          :key="obj.icon"
          target="_blank"
          class="mx-4"
          :icon="obj.icon"
          variant="text"
        ></v-btn>
      </div>
    </div>
    <div class="d-flex justify-space-around pa-2">
      <div>
        <v-btn variant="text" to="/" height="80" min-width="200">
          <img class="logo" src="../assets/logo.svg" alt="Logo" />
        </v-btn>
      </div>
      <div class="d-none d-lg-flex align-center">
        <v-btn
          v-for="item in menuItems"
          :key="item.label"
          class="text-h5 font-weight-light"
          variant="text"
          :to="item.to"
          color="#DDBEAA"
        >
          {{ item.label }}
        </v-btn>
        <v-btn
          class="text-h5 font-weight-light text-white"
          to="/hearingTest"
          color="#5ba199"
        >
          Online İşitme Testi
        </v-btn>
      </div>
      <div class="d-flex d-sm-flex d-lg-none align-center">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-app-bar-nav-icon
              color="#5ba199"
              v-bind="props"
            ></v-app-bar-nav-icon>
          </template>

          <v-list class="responsiveMenu">
            <v-list-item>
              <v-list-item-title class="d-flex flex-column">
                <v-btn
                  v-for="item in menuItems"
                  :key="item.label"
                  class="text-h6 font-weight-light"
                  variant="text"
                  :to="item.to"
                  color="#DDBEAA"
                >
                  {{ item.label }}
                </v-btn>
              </v-list-item-title>
              <v-list-item-title>
                <v-btn
                  to="/hearingTest"
                  class="text-h6 font-weight-light text-white"
                  color="#5ba199"
                >
                  Online İşitme Testi
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data: () => ({
    drawer: false,
    menuItems: [
      { label: "İşitme", to: "/hearing" },
      { label: "Ürünler", to: "/products" },
      { label: "Fiyat Listesi", to: "/priceList" },
      { label: "Koklear İmplant", to: "/kocklearImplant" },
      { label: "SGK Ödeme Payı", to: "/sgkShare" },
    ],
    icons: [
      { icon: "mdi-twitter", src: "https://twitter.com/88grupisitme" },
      {
        icon: "mdi-facebook",
        src: "https://www.facebook.com/people/88-Group-%C4%B0%C5%9Fitme-Cihazlar%C4%B1-Merkezi/100068274998344/",
      },
      {
        icon: "mdi-whatsapp",
        src: "https://wa.me/+905459194437?text=Merhaba! Sizden bir konu hakkında bilgi almak istiyorum.",
      },
      {
        icon: "mdi-instagram",
        src: "https://www.instagram.com/88saglik/",
      },
    ],
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 200px;
  height: 60px;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 150px;
    height: auto;
  }
}
</style>
