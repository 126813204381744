<template>
  <Parallax />
  <div class="pageHHH mb-8">
    <div class="d-flex justify-start text-h4 text-sm-h2">İşitme Kaybı</div>
    <v-divider class="mt-2"></v-divider>
    <div class="widthH d-flex flex-column flex-sm-row justify-space-between mt-8 mt-sm-16">
      <div class="widthB mr-2">
        <div class="d-flex justify-start font-weight-light text-h4">
          İşitme Kaybını Anlama
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          İşitme sağlığı, genel sağlığımız için hayati öneme sahiptir. 2024 yılı
          sonuna kadar, dünya çapında 1,1 milyar kişinin işitme kaybından
          etkileneceği tahmin edilmektedir, bu da dünya nüfusunun yaklaşık
          %16'sına denk gelmektedir. Üstelik bu sayılar, işitme kaybı yaşayan
          insanların genellikle yardım almadan önce 10 yıl kadar beklediğini
          göstermektedir.
        </div>
        <div class="d-flex text-left font-weight-regular mt-6">
          Yaşamın keyfini sonuna dek çıkarmak, işitme sağlığınıza da odaklanmayı
          içerir. Size bu konuda tam destek sunuyor ve sağlıklı işitmenin
          önemini vurguluyoruz. Herkesin işitme çözümlerine erişebilmesi için
          çalışıyoruz ve yaşam kalitenizi artırmak adına çözümler sunuyoruz.
          Sağlıklı işitme, hayatın tadını çıkarmanız ve her anın keyfini
          çıkarmanız için önemlidir. Siz de bu önemli konuda güvenilir ve uzman
          bir partnerle birlikte olabilirsiniz.
        </div>
      </div>
      <div class="widthB mt-4">
        <v-img
          class=""
          :width="576"
          :src="require('@/assets/hearing.png')"
        ></v-img>
      </div>
    </div>
    <div class="d-flex justify-start font-weight-light text-h4 mt-12">
      İşitme Kaybının Olası Etkileri
    </div>
    <div class="widthH d-flex flex-column flex-sm-row text-left font-weight-regular mt-4">
      İşitme kaybının etkileri, hayatımızın pek çok alanında kendini
      gösterebilir. Azalan dikkat, konuşmaları anlamakta zorlanma, hafızada
      zayıflama gibi belirtiler, günlük iletişimde ve iş hayatında karşılaşılan
      zorlukları artırabilir. Alışık olmadığı ortamlarda tedirginlik yaşama ve
      başkalarıyla iletişimde güçlük çekme, sosyal hayattan uzaklaşma ve
      yalnızlık hissi gibi duygusal etkiler de işitme kaybının yol açtığı
      sonuçlardan biridir.
    </div>
    <div class="widthH d-flex text-left font-weight-regular mt-4">
      İşitme zorluğu, iş performansında düşüşe neden olabilir ve asabiyet, stres
      ve depresyon gibi duygusal sorunlarla ilişkilendirilebilir. Bu durum,
      kişisel yaşamımızda ve sosyal ilişkilerimizde olumsuz etkiler yaratabilir.
    </div>
    <div class="widthH d-flex text-left font-weight-regular mt-4">
      Ancak unutmayın ki işitme kaybı, çözümü olmayan bir sorun değildir. Modern
      işitme çözümleri ve teknolojileri sayesinde bu zorlukların üstesinden
      gelmek mümkündür. Uzman işitme sağlayıcıları ile çalışarak, yaşam
      kalitenizi artırabilir, iletişim becerilerinizi geliştirebilir ve sosyal
      hayatınızı renklendirebilirsiniz. İşitme kaybının getirdiği olumsuz
      etkileri azaltmak için adım atmak, yaşamınızda olumlu ve güçlü bir değişim
      yaratabilir. Unutmayın, işitme sağlığına odaklanmak, daha mutlu ve
      sağlıklı bir yaşamın anahtarı olabilir.
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Hearing",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageHHH {
  margin: 2rem 20rem 0 20rem;
}

.widthH {
  width: 100% !important;
}

@media only screen and (max-width: 1440px) {
  .pageHHH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageHHH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthH {
    width: 100%;
  }
}
</style>