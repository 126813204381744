<template>
  <Parallax />
  <div class="pagePP mb-8">
    <div class="d-flex justify-start text-h4 text-sm-h2">Fiyat Listelerimiz</div>
    <v-divider class="mt-2"></v-divider>
    <v-expansion-panels variant="popout" class="my-8">
      <v-expansion-panel v-for="item in items" :key="item">
        <v-expansion-panel-title>
          <div class="d-flex justify-start font-weight-regular">
            {{ item.title }}
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div>
            <v-list>
              <v-list-item v-for="list in item.src" :key="list">
                <v-img
                  width="100%"
                  cover
                  :src="require(`@/assets/${list}.png`)"
                ></v-img>
              </v-list-item>
            </v-list>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PriceList",
  components: {
    Parallax,
  },
  data: () => ({
    items: [
      { title: "Vista Modelleri", src: ["vistaPrice"] },
      { title: "Resound Modelleri", src: ["resoundOne1", "resoundOne2", "resoundOne3", "resoundOne4"] },
    ],
  }),
};
</script>

<style>
.pagePP {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 48%;
}

@media only screen and (max-width: 1440px) {
  .pagePP {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pagePP {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthB {
    width: 100%;
  }
}
</style>