<template>
  <Parallax />
  <div class="pageKK mb-10">
    <div class="d-flex justify-start text-h4 text-sm-h2">Koklear İmplant</div>
    <v-divider class="mt-2"></v-divider>
    <div class="text-left font-weight-light text-h5 mt-8">
      Eğer siz ya da bir yakınınız işitme cihazlarından yeterli fayda
      sağlamıyorsanız bir koklear implant size yardımcı olabilir.
    </div>

    <div class="d-flex flex-column flex-sm-row justify-space-between mt-8 mt-sm-16">
      <div class="widthB">
        <v-img
          :src="require('@/assets/cochlearImplant.jpg')"
        ></v-img>
      </div>
      <div class="widthB align-self-center mt-4">
        <div class="d-flex text-left font-weight-regular mt-4">
          İşitme cihazları çoğu seslerin daha yüksek çıkmasına neden olur.
          Koklear implantlar farklıdır; kulağın hasarlı kısmının yanından
          geçerek doğrudan işitme sinirini uyarırlar.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          Böylece seslerin netliği artar ve konuşmaları anlama yetiniz daha iyi
          hale gelir. Sosyal durumlarda kendinize güveninizi geri kazanmanıza,
          arkadaşlarınız ve ailenizle birlikte olmanıza ve daha eksiksiz bir
          yaşam sürmenize de yardımcı olabilir.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          Yakın tarihli bir çalışmada, koklear implantları olan kişilerin
          cümleleri, daha önce işitme cihazları ile yaşadıkları deneyime kıyasla
          sekiz kat daha iyi anlayabildikleri gösterilmiştir.
        </div>
      </div>
    </div>
    <div class="d-flex justify-start text-h3 font-weight-light mt-16">
      Medel Ürünlerimiz
    </div>
    <div class="d-flex flex-column flex-sm-row  justify-space-between mt-8">
      <div class="widthB align-self-center">
        <div class="d-flex text-left font-weight-regular mt-4">
          Yaklaşık 40 yıl önce MED-EL kurucuları Dr. Ingeborg ve Dr. Erwin
          Hochmair, mikro elektronik çok kanallı koklear implantı
          keşfettiklerinde dünyayı değiştirdiler. Bu yüksek teknolojiye sahip
          aygıt, çok ileri derecede işitme kaybı olan kişilerin yalnızca
          işitmesine değil aynı zamanda konuşmasına, öğrenmesine ve
          sevdikleriyle önceden hiç olmadığı gibi iletişim kurabilmesine de
          olanak sağladı.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          Bugüne dek hızla artarak, dünyanın 140’tan fazla ülkesinde yaşayan
          binlerce insan MED-EL cihazları sayesinde işitiyor. Alanında uzman
          kadromuz 1995 yılından beri Türkiye'deki kullanıcılara yardım
          etmektedir. Ülkede sizin için doğru işitme çözümünü seçmeye yardımcı
          olan uzmanlara sahip bir klinik ağımız bulunmaktadır.
        </div>
      </div>
      <div class="widthB">
        <v-img
          class=""
          :width="576"
          :src="require('@/assets/medel.jpeg')"
        ></v-img>
      </div>
    </div>
    <div class="d-flex justify-start text-h4 font-weight-light mt-8 mt-sm-16">
      Rondo 2
    </div>
    <div class="d-flex flex-column flex-sm-row  justify-space-between mt-8">
      <div class="widthB">
        <v-img
          class=""
          :width="576"
          :src="require('@/assets/rondo2.jpeg')"
        ></v-img>
      </div>
      <div class="widthB align-self-center">
        <div class="d-flex text-left font-weight-regular mt-4">
          RONDO 2 kullanımı kolay ses işlemcisidir. Şık, göze çarpmayan tasarımı
          ve kablosuz şarj özelliği ile RONDO 2, gün boyu zahmetsizce işitmenizi
          sağlar.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          RONDO 2'nin kullanımı o kadar kolaylaştırıldı ki, taktığınızı bile
          unutacaksınız. Yenilikçi kablosuz şarj etme özelliğinin yanı sıra
          RONDO 2'de yalnızca bir açma/kapama düğmesi vardır ve sizin için
          otomatik olarak ses seviyelerini ayarlar Akıllı kablosuz aksesuarları
          kullanarak telefonunuza veya televizyonunuza bağlanması kolaydır ve
          kompakt tasarımı sayesinde saçınızın altında göze çarpmaz.
        </div>
      </div>
    </div>
    <div class="text-left font-weight-regular mt-8">
      RONDO 2'niz ile fark yaratmayı tercih ederseniz ses işlemcinizi öne
      çıkarmak için çeşitli renklerdeki Tasarım Kapaklar mevcuttur. Sanattan
      esinlenilen şık tasarımlardan klasik desenlere ve vahşi hayvan baskılarına
      kadar, RONDO 2'nize en sevdiğiniz kapağı kolayca takabilir ve görünüşünüzü
      anında değiştirebilirsiniz.
    </div>
    <div class="">
      <v-img
        class=""
        :aspect-ratio="1"
        :src="require('@/assets/rondo2custom.png')"
      ></v-img>
    </div>
    <div class="d-flex justify-start text-h4 font-weight-light mt-8 mt-sm-16">
      Rondo 3
    </div>
    <div class="d-flex text-left font-weight-regular mt-4">
      Kablosuz ses aktarımı, kablosuz şarj özelliği ile RONDO 3, gün boyunca
      kolay bir şekilde işitmenizi sağlar.
    </div>
    <div class="d-flex flex-column flex-sm-row justify-space-between">
      <div class="widthB align-self-center">
        <div class="d-flex text-left font-weight-regular mt-4">
          Ayarları ve pilini değiştirmeye gerek kalmadan kesintisiz işitme
          sağlar. RONDO 3 ile sevdiklerinizle bağlantı kurmak artık çok kolay.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          - Tam gün işitme
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          - 30’dan fazla kapak tasarımı
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          - Tüm işitme cihazlarıyla uyumlu
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          RONDO 3’ün şarj edilebilir entegre Lityum-iyon bataryası, size tam gün
          enerji sağlar. RONDO 3’ü uyurken şarj edin ve tüm gün işitmeye
          hazırlanın.
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          RONDO 3’ü şarj aletinin ortasına yerleştirin, kabloyla bağlamaya gerek
          kalmadan otomatik olarak şarj olur! Artık güne içiniz rahat devam
          edebilirsiniz çünkü RONDO 3 ihtiyacınız olduğunda kullanıma hazır
          olacak.
        </div>
      </div>
      <div class="widthB">
        <v-img :src="require('@/assets/rondo3.jpeg')"></v-img>
      </div>
    </div>
    <div class="d-flex justify-center">
      <v-img
        class=""
        :width="576"
        :src="require('@/assets/rondo3custom.jpg')"
      ></v-img>
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  name: "KocklearImplant",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageKK {
  margin: 2rem 20rem 0 20rem;
}

.widthB {
  width: 48%;
}

@media only screen and (max-width: 1440px) {
  .pageKK {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageKK {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthB {
    width: 100%;
  }
}
</style>