<template>
  <div class="d-flex flex-column footer">
    <v-divider class="mb-4"></v-divider>
    <div class="d-flex flex-column flex-sm-row justify-sm-center">
      <div class="footerCard d-flex flex-column">
        <v-btn to="/hearing" variant="text" color="#DDBEAA"> İşitme </v-btn>
        <v-btn to="/products" variant="text" color="#DDBEAA"> Ürünler </v-btn>
        <v-btn to="/priceList" variant="text" color="#DDBEAA">
          Fiyat Listesi
        </v-btn>
      </div>
      <div class="footerCard d-flex flex-column">
        <v-btn to="/kocklearImplant" variant="text" color="#DDBEAA">
          Koklear İmplant
        </v-btn>
        <v-btn to="/sgkShare" variant="text" color="#DDBEAA">
          SGK Ödeme Payı
        </v-btn>
        <v-btn
          to="/hearingTest"
          class="text-h5 font-weight-light text-white mx-5 mx-sm-0 "
          color="#5ba199"
        >
          Online İşitme Testi
        </v-btn>
      </div>
    </div>

    <div>
      <v-btn
        color="#469597"
        v-for="obj in icons"
        :href="obj.src"
        :key="obj.icon"
        target="_blank"
        class="mx-4"
        :icon="obj.icon"
        variant="text"
      ></v-btn>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-center mt-2">
      Berkay Köse © {{ new Date().getFullYear() }}
      <strong class=""></strong>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: "Footer",
  data: () => ({
    icons: [
      { icon: "mdi-twitter", src: "https://twitter.com/88grupisitme" },
      {
        icon: "mdi-facebook",
        src: "https://www.facebook.com/people/88-Group-%C4%B0%C5%9Fitme-Cihazlar%C4%B1-Merkezi/100068274998344/",
      },
      {
        icon: "mdi-whatsapp",
        src: "https://wa.me/+905459194437?text=Merhaba! Sizden bir konu hakkında bilgi almak istiyorum.",
      },
      {
        icon: "mdi-instagram",
        src: "https://www.instagram.com/88saglik/",
      },
    ],
  }),
};
</script>

<style>
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>