<template>
  <Carousel class="mobile"/>
  <v-img class="forMobile mt-8" :src="require('@/assets/hearingAid.png')"></v-img>
  <div class="d-flex flex-column pageH mb-8">
    <h1 class="mt-8 text-h6 text-sm-h4">
      "88 Grup olarak işitme cihazı alanında lider bir konumdayız. Müşteri
      odaklı yaklaşımımız ve kaliteli ürün portföyümüzle işitme ihtiyaçlarınızı
      karşılıyor, size en uygun çözümleri sunuyoruz"
    </h1>
    <h3 class="text-center font-weight-regular my-10">
      İşitme cihazları alanında lider olan 88 Grup İşitme, modern teknoloji,
      yenilikçi ruh, en yüksek kalite standartları ve sektöre öncülük eden
      uzmanlıkla, işitme ihtiyaçlarınıza yönelik en iyi çözümleri sunmaya devam
      etmektedir. İşitme kaybı yaşayan herkes için daha fazlasını ve daha
      iyisini yapabileceğimize içtenlikle inanıyoruz ve işitme cihazı kullanmak,
      gözlük veya kontakt lens takmak kadar basit ve rutin bir hal alana kadar
      durmayacağız. Müşterilerimizin yaşam kalitesini artırmak ve işitme
      deneyimlerini mükemmelleştirmek için sürekli çaba sarf ediyoruz. Sonuçta,
      neden işitme cihazları da diğer sağlık ürünleri gibi basit ve yaygın
      kullanılan bir çözüm olmasın? Size en iyi işitme çözümleri sunmak için var
      gücümüzle çalışıyoruz ve işitme ihtiyaçlarınızı karşılamak için buradayız.
    </h3>
  </div>
  <v-parallax height="500" :src="require('@/assets/home.jpg')"></v-parallax>
  <div class="pageH mt-8 pb-8">
    <div class="d-flex justify-center text-h4">Ürünlerimiz</div>
    <ProductSlide class="mb-8"/>
  </div>
</template>

<script>
import Carousel from "../components/Carousel.vue";
import ProductSlide from "../components/ProductSlide.vue"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Carousel,
    ProductSlide
  },
};
</script>

<style>
.pageH {
  margin: 0rem 20rem 0rem 20rem;
}

.forMobile {
  display: none;
}

@media only screen and (max-width: 1440px) {
  .pageH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .forMobile {
    display: block; 
  }

  .mobile {
    display: none;
  }
}
</style>