<template>
  <div>
    <v-carousel cycle height="400" hide-delimiters>
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <v-sheet height="100%" color="#BBC6C8">
          <v-img
            width="100%"
            :src="require(`@/assets/${slide}`)"
          ></v-img>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CarouselItem",
  data() {
    return {
      slides: ["88grup.jpeg", "hearingAid.png", "hearingAidTwo.png"],
    };
  },
};
</script>

<style>
</style>