<template>
  <Parallax />
  <div class="pageOne mb-16">
    <div class="d-flex justify-start text-h2">ReSound One</div>
    <v-divider></v-divider>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="widthOne d-flex flex-wrap align-self-center">
        <div class="justify-start font-weight-light text-h4">
          Bambaşka bir işitme deneyimi
        </div>
        <div class="text-left font-weight-regular mt-4">
          Tıpkı parmak iziniz gibi, işitmeniz de eşsizdir. Artık kulak
          kanalınızın içine yerleştirilen ek bir mikrofon ile gerçek anlamda
          kişiselleştirilmiş, doğal ve daha eksiksiz sesler
          algılayabilirsiniz.Biz buna M&RIE diyoruz. Sesi iletmek için kendi
          kulaklarınızı kullanır.
        </div>
      </div>
      <div class="widthOne">
        <v-img
          class=""
          :width="576"
          :src="require('@/assets/resoundOne.png')"
        ></v-img>
      </div>
    </div>
    <v-icon class="mt-16" icon="mdi-pencil-ruler" size="x-large"></v-icon>
    <div class="d-flex justify-center font-weight-light text-h5 text-teal">
      Yeni Tasarım
    </div>
    <div class="d-flex justify-center font-weight-light mt-4 text-h4">
      Bireysel ihtiyaçlarınızı karşılamak için tasarlandı
    </div>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="widthOne">
        <v-img :width="576" :src="require('@/assets/resoundOne.png')"></v-img>
      </div>
      <div class="align-self-center widthOne">
        <div class="d-flex text-start font-weight-light text-h6">
          Dünyayı kendi kulaklarınızla dinleyin
        </div>
        <div class="d-flex text-left font-weight-thin mt-2">
          M&RIE tasarımı, en doğal sesimizi vermek için benzersiz kulak
          şeklinizi kullanır.
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="d-flex text-start font-weight-light text-h6 mt-2">
          Nerede olursanız olun daha iyi işitin
        </div>
        <div class="d-flex text-left font-weight-thin mt-2">
          Tüm Erişim Yönlülüğü, sizin için önem taşıyan neyse onu işitmeniz için
          en iyi konumda olmanızı sağlar.
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="d-flex text-start font-weight-light mt-2 text-h6">
          Önünüzdeki konuşmaya odaklanın
        </div>
        <div class="d-flex text-left font-weight-thin mt-2">
          En zorlu ortamlarda bile harika bire bir işitme deneyimi için Ultra
          Focus'u etkinleştirin.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ResoundOne",
  components: {
    Parallax,
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.pageOne {
  margin: 2rem 20rem 0 20rem;
}

.widthOne {
  width: 45%;
}

@media only screen and (max-width: 1440px) {
  .pageOne {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageOne {
    margin: 2rem 2rem 0rem 2rem !important;
  }

  .widthOne {
    width: 100% !important;
  }
}
</style>