
<template>
  <v-sheet class="mx-auto mt-16" color="#E5E3E4" elevation="8" max-width="100%">
    <!-- <v-slide-group class="pa-4" selected-class="bg-success" show-arrows="false">
      <v-slide-group-item v-for="model in models" :key="model">
        <v-card class="mx-6 my-6" max-width="175" hover :to="model.src">
          <v-img
            class="align-end text-white"
            height="200"
            :src="require(`@/assets/${model.src}.png`)"
          >
          </v-img>
          <v-card-title class="text-teal">{{ model.name }}</v-card-title>
          <v-card-text>
            <div>{{ model.desc }}</div>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group> -->
    <v-card class="mt-10">
      <v-tabs
        class="mobile"
        v-model="tab"
        color="teal-accent-4"
        align-tabs="center"
      >
        <v-tab :value="0">Vista DX</v-tab>
        <v-tab :value="1">Vista B</v-tab>
        <v-tab :value="2">Vista B (Kanal içi)</v-tab>
        <v-tab :value="3">ReSound One</v-tab>
        <v-tab :value="4">ReSound LiNX Quattro</v-tab>
        <v-tab :value="5">ReSound ENZO Q</v-tab>
        <v-tab :value="6">ReSound Key</v-tab>
        
      </v-tabs>
      <v-window v-model="tab" show-arrows="true">
        <v-window-item v-for="model in models" :key="model" :value="model">
          <v-container fluid>
            <v-row>
              <v-card
                class="mx-auto my-6"
                max-width="auto"
                hover
                :to="model.src"
              >
                <v-img
                  class="align-end text-white"
                  height="200"
                  :src="require(`@/assets/${model.src}.png`)"
                >
                </v-img>
                <v-card-title class="text-teal">{{ model.name }}</v-card-title>
                <v-card-text>
                  <div>{{ model.desc }}</div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-container>
        </v-window-item>
      </v-window>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductSlide",
  data: () => ({
    tab: null,
    models: [
      {
        name: "Vista DX",
        desc: "Vista DX, harika ses kalitesi ve bağlantı özelliklerine sahip şık ve konforlu bir işitme cihazı ailesidir. Modellerin çoğu aynı zamanda şarj edilebilirdir.",
        src: "vistaDX",
      },
      {
        name: "Vista B",
        desc: "Vista, 50 yılı aşkın süredir işitme alanında yenilikler yapan bir şirket tarafından geliştirilmiştir. Vista işitme çok çeşitli stillerde ve teknoloji seviyelerinde mevcuttur.",
        src: "vistaB",
      },
      {
        name: "Vista B (Kanal İçi)",
        desc: "Vista, 50 yılı aşkın süredir işitme alanında yenilikler yapan bir şirket tarafından geliştirilmiştir. Vista işitme çok çeşitli stillerde ve teknoloji seviyelerinde mevcuttur.",
        src: "vistaBPlug",
      },
      {
        name: "ReSound ONE",
        desc: "Tıpkı parmak iziniz gibi, işitmeniz de eşsizdir. Gerçek anlamda kişiselleştirilmiş, daha eksiksiz bir ses deneyimi için M&RIE Mikrofon ve Kulak İçi Alıcı tasarımıyla tanışın.",
        src: "resoundOne",
      },
      {
        name: "ReSound LiNX Quattro",
        desc: "Her ortamda ince ayrıntılar ve berrak sesler sunan yüksek performanslı işitme cihazlarından oluşan koleksiyonumuzu keşfedin.",
        src: "resoundQuattro",
      },
      {
        name: "ReSound ENZO Q",
        desc: "İleri ve çok ileri derecede işitme kaybı için net, rahat ve yüksek kaliteli ses sunan, yaşamı zenginleştiren işitme cihazı - sadece güçlü olmaktan çok daha fazlası.",
        src: "resoundEnzo",
      },
      {
        name: "ReSound Key",
        desc: "Günlük hayatınız için mükemmel bir sese sahip, modern, kullanımı kolay, vazgeçilmez işitme özellikleriyle harika bir işitme deneyimine hoş geldiniz. .",
        src: "resoundKey",
      },
    ],
  }),
};
</script>

<style>
@media only screen and (max-width: 500px) {
  .mobile {
    display: none;
  }
}
</style>