<template>
  <Parallax />
  <div class="pageHH mb-8">
    <div class="d-flex justify-start text-h4 text-sm-h2 mt-10">
      Çevrimiçi İşitme Testi
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex justify-start text-h6 text-sm-h4 mt-14">
      Üç dakikalık işitme testimizi yapın
    </div>
    <div class="d-flex text-left text-h6 font-weight-light mt-6">
      Bir işitme uzmanı ile yapılan görüşmenin yerini hiçbir şey tutmasa da
      Selçuk İşitme Merkezi çevrimiçi işitme taraması işitmeniz hakkında bazı
      hızlı ve yararlı geri bildirimler sağlayabilir.
    </div>
    <div class="d-flex justify-start text-h6 text-teal text-sm-h4 mt-14">
      Buradan başlayın
    </div>
    <div class="d-flex flex-wrap mt-10">
      <div class="widthH mr-6">
        <v-select
          label="Gün"
          :items="[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
          ]"
        ></v-select>
      </div>
      <div class="mr-6 widthH">
        <v-select
          label="Ay"
          :items="[
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
          ]"
        ></v-select>
      </div>
      <div class="mr-6 widthH">
        <v-select label="Yıl" :items="yearsArr"></v-select>
      </div>
      <div class="mr-6 widthH">
        <v-select label="Cinsiyet" :items="['Erkek', 'Kadın']"></v-select>
      </div>
      <div class="mr-6 widthH">
        <v-btn
          class="text-h5 font-weight-light text-white"
          href="https://www.signia.net/tr-tr/service/isitmetesti/"
          target="_blanc"
          color="#5ba199"
        >
          Teste Başla
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "HearingTest",
  components: {
    Parallax,
  },
  data() {
    // eslint-disable-next-line no-undef
    let yearsArr = [];
    let firstYear = 1924;

    for (let i = 0; i < 100; i++) {
      let yearString = firstYear.toString();
      console.log(yearString);
      yearsArr.push(yearString);
      firstYear++;
    }

    return { yearsArr };
  },
};
</script>

<style>
.pageHH {
  margin: 0rem 20rem 0rem 20rem;
}

.widthH {
  width: 17%;
}

@media only screen and (max-width: 1440px) {
  .pageHH {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageHH {
    margin: 2rem 2rem 0rem 2rem;
  }

  .widthH {
    width: 100%;
  }

  .mobile {
    display: none;
  }
}
</style>