<template>
  <Parallax />
  <div class="pageSS mb-8">
    <div class="d-flex justify-start text-h4 text-sm-h2">SGK Ödeme Payı</div>
    <v-divider class="mt-2"></v-divider>
    <div class="text-left font-weight-light text-h6 text-sm-h5 mt-8">
      SGK aşağıda göstermiş olduğumuz kriterler doğrultusunda bir ödeme payı
      sunmaktadır.
    </div>
    <div class="mt-12 text-start">
      <span class="font-weight-bold">0-4</span> yaş için veli çalışıyor ise net <span class="font-weight-bold text-teal-lighten-1" >4.360,32 TL</span>, veli emekli ise <span class="font-weight-bold text-teal-lighten-1">5.450,40 TL</span>
    </div>
    <div class="mt-4 text-start">
      <span class="font-weight-bold">5-12</span> yaş için veli çalışıyor ise net <span class="font-weight-bold text-teal-lighten-1">3.875,84 TL</span>, veli emekli ise <span class="font-weight-bold text-teal-lighten-1">4.844,80 TL</span>
    </div>
    <div class="mt-4 text-start">
      <span class="font-weight-bold">13-18</span> yaş için veli çalışıyor ise net <span class="font-weight-bold text-teal-lighten-1">3.633,60 TL</span>, veli emekli ise <span class="font-weight-bold text-teal-lighten-1">4.542,00 TL</span>
    </div>
    <div class="mt-4 text-start">
      <span class="font-weight-bold">18</span> yaş üzeri için kişi çalışıyorsa net <span class="font-weight-bold text-teal-lighten-1">2.422,40 TL</span>, emekli ise <span class="font-weight-bold text-teal-lighten-1">3.028,00 TL</span>
    </div>
  </div>
</template>

<script>
import Parallax from "../components/Parallax.vue";

export default {
  name: "SGKShare",
  components: {
    Parallax,
  },
};
</script>

<style>
.pageSS {
  margin: 2rem 20rem 0 20rem;
}

@media only screen and (max-width: 1440px) {
  .pageSS {
    margin: 2rem 10rem 0rem 10rem;
  }
}

@media only screen and (max-width: 500px) {
  .pageSS {
    margin: 2rem 2rem 0rem 2rem;
  }
}
</style>