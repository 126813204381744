<template>
  <Parallax />
  <div class="pageQne mb-16">
    <div class="d-flex justify-start text-h2">ReSound LiNX Quattro</div>
    <v-divider class="mt-2"></v-divider>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="widthQ align-self-center ">
        <div class="d-flex text-start font-weight-light text-h4">
          Küçük ve mükemmel tasarım
        </div>
        <div class="d-flex text-left font-weight-regular mt-4">
          Mini boyutlu Hoparlör Kulak İçinde model, göze çarpmayan estetik bir
          tasarıma sahiptir. Küçük boyutu ve tarzıyla neredeyse görünmezdir.
        </div>
      </div>
      <div class="widthQ">
        <v-img
          class=""
          width="60%"
          :src="require('@/assets/resoundQuattro.png')"
        ></v-img>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="widthQ">
        <v-img :widthQ="576" :src="require('@/assets/oldGuy.jpg')"></v-img>
      </div>
      <div class="widthQ align-self-center mt-4">
        <div class="d-flex text-start font-weight-light text-h4">
          Tüm yelpazeye ses aktarımı
        </div>
        <div class="d-flex text-left font-weight-thin mt-4">
          Kablosuz işitme cihazlarınıza direkt stereo ses aktararak insanlara ve
          eğlenceye bağlı kalın. Tüm modeller, iOS ve AndroidTM aygıtlardan* da
          dahil olmak üzere ve ek bir aparata gerek kalmadan ses aktarımının tam
          spektrumunu sunmaktadır.
        </div>
        <div class="d-flex text-left font-weight-thin mt-4">
          *Bluetooth versiyon 5.0'a sahip Android versiyon 10 olan ve işitme
          cihazına ses aktarım özelliği aktif olan Android aygıtlar için
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-space-between mt-16">
      <div class="widthQ align-self-center ">
        <div class="d-flex text-start font-weight-light text-h5">
          Şarj edilebilir çözüm
        </div>
        <div class="d-flex text-left font-weight-thin mt-4 text-h4">
          Sizi asla yarı yolda bırakmayacak, cep büyüklüğünde güç
        </div>
        <div class="d-flex text-left font-weight-thin mt-4">
          Uzun süre dayanan güvenilir enerjisi ve pratik, sezgisel erişilebilirliğiyle gün boyu kendinize güvenin.
        </div>
      </div>
      <div class="widthQ mt-4">
        <v-img :widthQ="576" :src="require('@/assets/charger.jpeg')"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "../../components/Parallax.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ResoundQuattro",
  components: {
    Parallax,
  },
  data: () => ({
    model: null,
  }),
};
</script>

<style>
.pageQne {
  margin: 2rem 20rem 0 20rem;
}

.widthQ {
  width: 45%;
}

@media only screen and (max-width: 1440px) {
  .pageQne {
    margin: 2rem 10rem 0rem 10rem !important;
  }
}

@media only screen and (max-width: 390px) {
  .pageQne {
    margin: 2rem 2rem 0rem 2rem !important;
  }

  .widthQ {
    width: 100% !important;
  }
}
</style>